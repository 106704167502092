<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Division" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="division"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('warehouse_config.division') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="bankLoanFacility.division_id"
                              :options="divisionList"
                              id="division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :disabled="isWareHouseUser"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Region" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="region_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('warehouse_config.region') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="bankLoanFacility.region_id"
                              :options="regionLists"
                              id="region_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :disabled="isWareHouseUser"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="District" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="district"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('warehouse_config.district') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="bankLoanFacility.district_id"
                              :options="districtList"
                              id="district_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :disabled="isWareHouseUser"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Upazila" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="upazilla_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('warehouse_config.upazilla') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="bankLoanFacility.upazilla_id"
                              :options="upazilaList"
                              id="upazilla_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :disabled="isWareHouseUser"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Union" rules="">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="union_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('globalTrans.union') }} <span class="text-danger"></span>
                            </template>
                            <b-form-select
                              plain
                              v-model="bankLoanFacility.union_id"
                              :options="unionList"
                              id="union_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              :disabled="isWareHouseUser"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Warehouse Name" vid="warehouse_name" rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="warehouse_name"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{$t('warehouse_config.warehouse_name')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="bankLoanFacility.warehouse_id"
                            :options="warehouseNameList"
                            id="warehouse_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :disabled="isWareHouseUser"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>

                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Bank name" vid="bank_id"  rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="bank_id"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('warehouse_config.bank_name')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-select
                              plain
                              v-model="bankLoanFacility.bank_id"
                              :options="bankList"
                              id="bank_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Branch Name" vid="branch_id">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="branch_id"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('warehouse_config.branch_name')}}
                              </template>
                              <b-form-select
                              plain
                              v-model="bankLoanFacility.branch_id"
                              :options="branchList"
                              id="branch_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>

                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Interest Rate" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="interest_rate"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('warehouse_config.interest_rate')}} <span class="text-danger">*</span>
                              </template>
                            <b-form-input
                              id="interest_rate"
                              v-model="bankLoanFacility.interest_rate"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id"  rules="required|min_value:1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-select
                              plain
                              v-model="bankLoanFacility.fiscal_year_id"
                              :options="fiscalList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="File Name (En)">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="file_name"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('warehouse_config.file_name_enf')}}
                              </template>
                            <b-form-input
                              id="file_name"
                              v-model="bankLoanFacility.file_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="File Name (Bn)">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="file_name_bn"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('warehouse_config.file_name_bn')}}
                              </template>
                            <b-form-input
                              id="file_name_bn"
                              v-model="bankLoanFacility.file_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" lg="6">
                        <ValidationProvider name="Attached file" vid="attachment">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="attachment"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('warehouse_config.attached_file')}}
                              </template>
                            <b-form-file
                              id="attachment"
                              v-on:change="onFileChange"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-file>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col sm="12" lg="6">
                        <ValidationProvider name="Remarks (En)" vid="remarks">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="remarks"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('warehouse_config.remarksf')}}
                              </template>
                              <b-form-input
                              plain
                              type="text"
                              v-model="bankLoanFacility.remarks"
                              id="remarks"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-input>

                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Remarks (Bn)" vid="remarks">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="remarks_bn"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('warehouse_config.remarks_Bn')}}
                            </template>
                            <b-form-input
                            plain
                            type="text"
                            v-model="bankLoanFacility.remarks_bn"
                            id="remarks_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-input>

                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12"></b-col>
                    </b-row>
                    <div class="row">
                      <div class="col-sm-2"></div>
                      <div class="col">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { warehouseBanlLoanFacilityStore, warehouseBanlLoanFacilityUpdate } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getBankLoanFacilityData()
      this.bankLoanFacility = tmp
    }
  },
  mounted () {
    core.index()
    if (this.isWareHouseUser) {
      this.bankLoanFacility.division_id = this.authUser.office_detail.division_id
      this.bankLoanFacility.region_id = this.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
      this.bankLoanFacility.district_id = this.authUser.office_detail.district_id
      this.bankLoanFacility.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
      this.bankLoanFacility.union_id = this.authUser.office_detail.union_id ?? 0
      const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
      this.bankLoanFacility.warehouse_id = warehouse.value
    }
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      bankLoanFacility: {
        division_id: 0,
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        bank_id: 0,
        branch_id: 0,
        interest_rate: '',
        fiscal_year_id: 0,
        file_name: '',
        file_name_bn: '',
        remarks: '',
        remarks_bn: '',
        attachment: []
      },
      attachment_two: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      warehouseNameList: [],
      branchList: []
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    fiscalList: function () {
        return this.$store.state.warehouse.fiscalYearList.filter(item => item.status === 0)
    },
      ...mapGetters({
      commonObj: 'commonObj'
    }),
    bankList: function () {
      return this.$store.state.commonObj.bankObj.bankList.filter(item => item.status === 0)
    },
    regionLists: function () {
        return this.$store.state.warehouse.regionList.filter(item => item.status === 0)
    },
    regionDetailList: function () {
      return this.$store.state.warehouse.regionDetailList
    }
  },
  watch: {
    'bankLoanFacility.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'bankLoanFacility.region_id': function (newVal, oldVal) {
      this.getRegionDistrictList(newVal)
    },
    'bankLoanFacility.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'bankLoanFacility.upazilla_id': function (newVal, oldVal) {
         this.unionList = this.getUnionList(newVal)
      this.warehouseNameList = this.getWarehouseName(newVal, 'UPZ')
    },
    'bankLoanFacility.union_id': function (newVal, oldVal) {
      this.warehouseNameList = this.getWarehouseName(newVal, 'UP')
    },
    'bankLoanFacility.bank_id': function (newVal, oldVal) {
      this.branchList = this.getBranchList(newVal)
    }
  },
  methods: {
    getBankLoanFacilityData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    onFileChange (e) {
      this.attachment_two = e.target.files[0]
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.bankLoanFacility).map(key => {
        if (key === 'attachment') {
          formData.append(key, this.attachment_two)
        } else {
          formData.append(key, this.bankLoanFacility[key])
        }
      })
      if (this.bankLoanFacility.id) {
        result = await RestApi.postData(warehouseServiceBaseUrl, `${warehouseBanlLoanFacilityUpdate}/${this.id}`, formData)
      } else {
        result = await RestApi.postData(warehouseServiceBaseUrl, warehouseBanlLoanFacilityStore, formData, config)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data Update successfully' : 'Data saved successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getUnionList (unionId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (unionId) {
        return unionList.filter(union => union.upazilla_id === unionId)
      }

      return unionList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
      }

      return list
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          this.districtList = []
          const dataObject = response.filter(document => document.region_id === regionId)
          dataObject.map(obj => {
            const district = this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id)
            if (typeof district !== 'undefined') {
              const item = {
                value: obj.district_id,
                text: this.$i18n.locale === 'bn' ? district.text_bn : district.text_en
              }
              this.districtList.push(item)
            }
          })
        }
      })
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getBranchList (bankId = null) {
      if (bankId) {
        return this.commonObj.bankObj.branchList.filter(item => item.bank_id === bankId && item.status === 0)
      }
      return []
    }
  }
}

</script>
