export const testListApi = '/list'
export const testStoreApi = '/store'
export const testUpdateApi = '/update'
export const testToggleStatusApi = '/toggle-status'
// warehouse type start
export const warehouseTypeList = '/master-warehousetype/list'
export const warehouseTypeStore = '/master-warehousetype/store'
export const warehouseTypeUpdate = '/master-warehousetype/update'
export const warehouseTypeToggleStatus = '/master-warehousetype/toggle-status'
export const warehouseTypeDestroy = '/master-warehousetype/destroy'

export const SubGroupList = '/master-commodity-sub-group/list'
export const SubGroupStore = 'master-commodity-sub-group/store'
export const SubGroupUpdate = 'master-commodity-sub-group/update'
export const SubGroupToggleStatus = '/master-commodity-sub-group/toggle-status'
export const SubGroupDestroy = '/master-commodity-sub-group/destroy'

// commodity group start
export const warehouseGroupList = '/master-commodity-group/list'
export const warehouseGroupStore = '/master-commodity-group/store'
export const warehouseGroupUpdate = '/master-commodity-group/update'
export const warehouseGroupToggleStatus = '/master-commodity-group/toggle-status'
export const warehouseGroupDestroy = '/master-commodity-group/destroy'
// excel store
export const warehouseGroupExcelStore = '/commodity-group-excel/import'

// all table excel import
export const warehouseDataExcelStore = '/warehouse-data-excel/import'

// commodity name start
export const commodityNameList = '/master-commodity-name/list'
export const commodityNameStore = '/master-commodity-name/store'
export const commodityNameUpdate = '/master-commodity-name/update'
export const commodityNameToggleStatus = '/master-commodity-name/toggle-status'
export const commodityNameDestroy = '/master-commodity-name/destroy'

// commodity designation start
export const warehouseDesignationList = '/master-warehouse-designation/list'
export const warehouseDesignationStore = '/master-warehouse-designation/store'
export const warehouseDesignationUpdate = '/master-warehouse-designation/update'
export const warehouseDesignationToggleStatus = '/master-warehouse-designation/toggle-status'
export const warehouseDesignationDestroy = '/master-warehouse-designation/destroy'

// commodity market price start
export const warehouseMarketPriceList = '/master-market-price/list'
export const warehouseMarketPriceStore = '/master-market-price/store'
export const warehouseMarketPriceUpdate = '/master-market-price/update'
export const warehouseMarketPriceToggleStatus = '/master-market-price/toggle-status'
export const warehouseMarketPriceDestroy = '/master-market-price/destroy'

// warehouse information list
export const warehouseInformationList = '/master-warehouse-info/list'
export const warehouseInformationStore = '/master-warehouse-info/store'
export const warehouseInformationUpdate = '/master-warehouse-info/update'
export const warehouseInformationToggleStatus = '/master-warehouse-info/toggle-status'
export const warehouseInformationDestroy = '/master-warehouse-info/destroy'
// commodity fiscal year
export const warehouseFiscalList = '/master-fiscal-year/list'
export const warehouseFiscalStore = '/master-fiscal-year/store'
export const warehouseFiscalUpdate = '/master-fiscal-year/update'
export const warehouseFiscalToggleStatus = '/master-fiscal-year/toggle-status'
export const warehouseFiscalDestroy = '/master-fiscal-year/destroy'

// warehouse loan rate list
export const loanRateList = '/master-loan-rate/list'
export const loanRateStore = '/master-loan-rate/store'
export const loanRateUpdate = '/master-loan-rate/update'
export const loanRateToggleStatus = '/master-loan-rate/toggle-status'
export const loanRateDestroy = '/master-loan-rate/destroy'

// warehouse loan rate list
export const fiscalYearList = '/master-fiscal-year/list'

// warehouse region list
export const regionList = '/master-region-info/list'
export const regionStore = '/master-region-info/store'
export const regionUpdate = '/master-region-info/update'
export const regionToggleStatus = '/master-region-info/toggle-status'
export const regionDestroy = '/master-region-info/destroy'

// bank loan calculator
export const loanCalculatorList = '/master-bank-loan-calculator/list'
export const loanCalculatorStore = '/master-bank-loan-calculator/store'
export const loanCalculatorUpdate = '/master-bank-loan-calculator/update'
export const loanCalculatorToggleStatus = '/master-bank-loan-calculator/toggle-status'
export const loanCalculatorDestroy = '/master-bank-loan-calculator/destroy'

// warehouse level list
export const warehouseLevelList = '/master-warehouse-level/list'
export const warehouseLevelStore = '/master-warehouse-level/store'
export const warehouseLevelUpdate = '/master-warehouse-level/update'
export const warehouseLevelToggleStatus = '/master-warehouse-level/toggle-status'
export const warehouseLevelDestroy = '/master-warehouse-level/destroy'

// warehouse service start
export const warehouseServiceList = '/master-warehouse-service/list'
export const warehouseServiceStore = '/master-warehouse-service/store'
export const warehouseServiceUpdate = '/master-warehouse-service/update'
export const warehouseServiceListToggleStatus = '/master-warehouse-service/toggle-status'
export const warehouseServiceListDestroy = '/master-warehouse-service/destroy'

// warehouse Bank Loan Facility start
export const warehouseBanlLoanFacilityList = '/master-bank-loan/list'
export const warehouseBanlLoanFacilityStore = '/master-bank-loan/store'
export const warehouseBanlLoanFacilityUpdate = '/master-bank-loan/update'
export const warehouseBanlLoanFacilityToggleStatus = '/master-bank-loan/toggle-status'
export const warehouseBanlLoanFacilityListDestroy = '/master-bank-loan/destroy'

// warehouse Rent start
export const warehouseRentList = '/master-warehouse-rent/list'
export const warehouseRentStore = '/master-warehouse-rent/store'
export const warehouseRentUpdate = '/master-warehouse-rent/update'
export const warehouseRentToggleStatus = '/master-warehouse-rent/toggle-status'
export const warehouseRentListDestroy = '/master-warehouse-rent/destroy'

// warehouse Opening Balance Start
export const OpeningBalanceList = '/master-warehouse-opening-balance/list'
export const OpeningBalanceStore = '/master-warehouse-opening-balance/store'
export const OpeningBalanceUpdate = '/master-warehouse-opening-balance/update'
export const OpeningBalanceToggleStatus = '/master-warehouse-opening-balance/toggle-status'
export const OpeningBalanceDestroy = '/master-warehouse-opening-balance/destroy'
export const OpeningBalanceEdit = '/master-warehouse-opening-balance/edit'

// warehouse Report Header Start
export const reportHeadingList = '/master-warehouse-report-heading/list'
export const reportHeadingStore = '/master-warehouse-report-heading/store'
export const reportHeadingUpdate = '/master-warehouse-report-heading/update'
export const reportToggleStatus = '/master-warehouse-report-heading/toggle-status'
export const reportHeaderDestroy = '/master-warehouse-report-heading/destroy'

// user route
export const secretQuestionApi = 'secret-question-dropdown'
export const userList = '/user/list'
export const userStore = '/user/store'
export const userWarehouseStore = '/user/store-warehouse'
export const userDelete = '/user/toggle-status'

//  warehouse component dashboard api
export const warehouseSpaceInfoApi = '/warehouse-component-dashboard/warehouse-space-info'
export const warehouseStockStatusApi = '/warehouse-component-dashboard/warehouse-stock-status'

// service type list master
export const serviceTypeList = '/master-service-type/list'
export const serviceTypeStore = '/master-service-type/store'
export const serviceTypeUpdate = '/master-service-type/update'
export const serviceTypeToggleStatus = '/master-service-type/toggle-status'
export const serviceTypeAllList = '/master-service-type/list-all'

// pre requist check list master Api Routes
export const requistCheckList = '/master-check-list/list'
export const requistCheckStore = '/master-check-list/store'
export const requistCheckUpdate = '/master-check-list/update'
export const requistCheckToggleStatus = '/master-check-list/toggle-status'
export const requistCheckListAll = '/master-check-list/list-all'

// warehouse Category Api Routes
export const categoriesList = '/master-categories/list'
export const categoriesStore = '/master-categories/store'
export const categoriesUpdate = '/master-categories/update'
export const categoriesToggleStatus = '/master-categories/toggle-status'
export const categoriesListAll = '/master-categories/list-all'

// market info list
export const marketInfoList = '/master-market-infos/list'
export const marketInfoStore = '/master-market-infos/store'
export const marketInfoUpdate = '/master-market-infos/update'
export const marketInfoToggleStatus = '/master-market-infos/toggle-status'
export const marketInfoDestroy = '/master-market-infos/destroy'
